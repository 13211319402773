import React, { FC } from 'react'
import { Grid, Typography, Button, Container, Box } from '@mui/material'
import { Link } from 'gatsby'
import './styles.scss'

const RecaudoCTA: FC = () => {
  return (
    <div className="recaudo-cta">
      <Container>
        <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Typography align='center' color="text.white" gutterBottom variant="h4">
            Empieza hoy a descubrir
          </Typography>
          <Typography align='center' color="text.white" gutterBottom variant="h4">
            Ingenio Kushki
          </Typography>
          <Link to='/contacto'>
            <Button variant="contained" sx={{ my: { md: 2, sm: 3 } }} color="primary" className="ing-index-hero__button">Contáctanos</Button>
          </Link>
        </Grid>
      </Container>
    </div>
  )
}

export default RecaudoCTA
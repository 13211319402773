import React, { FC } from 'react'
import { Grid, Typography, Button, Container, Box, Card, CardContent  } from '@mui/material'
import marketing from './assets/images/marketing.svg';
import diverse from './assets/images/diverse.svg';
import report from './assets/images/report.svg';
import integration from './assets/images/integration.svg';

const RecaudoInKushki: FC = () => {
  return (
    <>
      <Container>
        <Grid container spacing={4} className="ing-index-hero__content">
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '2rem' }}>
            <Typography color="text.dark" gutterBottom variant="h1">
              En <strong>Ingenio Kushki</strong>
            </Typography>
            <Typography color="text.dark" gutterBottom variant="body2">
              Apoyamos a entidades públicas y privadas que requieren la capacidad tecnológica para que sus clientes puedan acceder a servicios de manera digital, brindándoles la última tecnología, optimizada en experiencia del usuario, marketing y procesamiento de pagos.
            </Typography>
            
          </Grid>
          <Grid container lg={6} md={6} sm={12} xs={12}
            spacing={{ xs: 2, md: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            columns={12}
            sx={{ position: 'relative', bottom: { xs: 0, sm: 0, md:22 }, left: { xs: "2rem", sm: "2rem", md:0 }}}
          >
            <Grid item sx={{display: 'flex', gap: '2rem', flexDirection:{ xs:'column', sm: 'column', md:'row' }}}>
              <Card sx={{ width: '280px', height: '230px' ,borderBottom: 5, borderBottomColor: '#8F4F8B' , pt: 2}}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <img src={marketing} alt="marketing" />
                  <Typography color="text.alternate" variant="h5">
                    Marketing
                  </Typography>
                  <Typography color="text.dark" variant="caption" textAlign={'center'} mt={'1rem'}>
                    Trabajamos en el posicionamiento de sus sitios, para un mayor alcance poblacional.
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ width: '280px', height: '230px' ,borderBottom: 5, borderBottomColor: '#8F4F8B' , pt: 2}}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <img src={diverse} alt="marketing" />
                  <Typography color="text.alternate" variant="h5">
                    Diversificación
                  </Typography>
                  <Typography color="text.dark" variant="caption" textAlign={'center'} mt={'1rem'}>
                    Damos diferentes alternativas a las entidades para multiplicar sus opciones de recaudo.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sx={{display: 'flex', gap: '2rem', flexDirection:{ xs:'column', sm: 'column', md:'row' } }}>
            <Card sx={{ width: '280px', height: '230px' ,borderBottom: 5, borderBottomColor: '#8F4F8B' , pt: 2}}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <img src={report} alt="marketing" />
                  <Typography color="text.alternate" variant="h5">
                    Reportes
                  </Typography>
                  <Typography color="text.dark" variant="caption" textAlign={'center'} mt={'1rem'}>
                    Ingenio Kushki ofrece una reportería avanzada, facilitando la operación y la conciliación de los recaudos digitales en los portales.
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ width: '280px', height: '230px' ,borderBottom: 5, borderBottomColor: '#8F4F8B' , pt: 2}}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <img src={integration} alt="marketing" />
                  <Typography color="text.alternate" variant="h5">
                    Integración
                  </Typography>
                  <Typography color="text.dark" variant="caption" textAlign={'center'} mt={'1rem'}>
                    Hacemos integraciones con las entidades sin importar el formato en que se pueden construir los datos.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default RecaudoInKushki
import React, { FC } from 'react'
import { Grid, Typography, Button, Container, Box } from '@mui/material'
import { Link } from 'gatsby'
import recaudoLogo from './assets/images/recaudoLogo.svg';
import heroRecaudo from './assets/images/heroRecaudo.svg';
import './styles.scss'

const RecaudoHero: FC = () => {
  return (
    <>
      <Container>
        <Grid container mt={"0.2rem"} p={"3rem"}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', mt:{xs:'4rem', sm:'4rem' ,md:'1rem'}, gap: '0.5rem' }}>
            <Typography color="text.white" gutterBottom variant="h2">
              Ofrecemos trámites digitales a diferentes entidades públicas y privadas en América Latina
            </Typography>
            <Grid>
              <Typography color="text.white" gutterBottom variant="body2">
                En Soluciones de Recaudo, brindamos lo último en tecnología para que todos los ciudadanos puedan acceder a servicios y trámites digitales.
              </Typography>
              <Typography color="text.white" gutterBottom variant="body2">
                Además, generamos valor agregado para facilitar los pagos con una excelente experiencia.
              </Typography>
            </Grid>
            <Box sx={{ width:{sm:'280px', md:'320px'} }}>
              <img src={recaudoLogo} alt="" width={"100%"}/>
            </Box>
            <Link to='/contacto'>
              <Button variant="outlined" sx={{ my: { md: 'none', sm: 3 } }} color="primary" className="ing-index-hero__button">Contáctanos</Button>
            </Link>
          </Grid>
          <Grid sx={{ display: { xs: 'none', md: 'block' }, position: 'relative', left: '-1rem', height:'450px'  }} item xs={12} md={6} >
            <img src={heroRecaudo} alt="intersect img" className="ing-index-hero__img" />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default RecaudoHero
import React, { FC } from 'react'
import Button from '../../Atoms'
import { Link } from 'gatsby';
import { Grid, Typography, Container, Box, CardContent, Card } from '@mui/material'
import ecuador from './assets/images/ecuador.svg';
import peru from './assets/images/peru.svg';
import colombia from './assets/images/colombia.svg';
import pagaGuayaquil from './assets/images/pagaGuayaquil.svg';
import pagaAnt from './assets/images/pagaAnt.svg';
import pichincha from './assets/images/pichincha.svg';
import electricaQuito from './assets/images/electricaQuito.svg';
import mintel from './assets/images/mintel.svg';
import guayasamin from './assets/images/guayasamin.svg';
import bancoNacion from './assets/images/bancoNacion.svg';
import covi from './assets/images/covi.svg';
import facilita from './assets/images/facilita.svg';
import poderJudicial from './assets/images/poderJudicial.svg';
import snr from './assets/images/snr.svg';
import runt from './assets/images/runt.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { relative } from 'path';

const RecaudoProducts: FC = () => {
  return (
    <>
      <Container>
        <Grid container spacing={4} xs={12} sm={12} md={12} lg={12} my={'4rem'} textAlign='center' justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography color="text.dark" gutterBottom variant="h2">
              Nuestros Productos
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{display: 'flex'}}>
              <img src={ecuador} alt="Ecuador"/>
              <Typography color="text.grey" variant="h3" sx={{position: 'relative', bottom: '-1.2rem', mx: '1.2rem'}}>
                Ecuador
              </Typography>
            </Box>
          </Grid>
          <Grid 
            container lg={12} md={12} sm={12} xs={12}
            spacing={{ xs: 'none', sm: 'none', md: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ py:'2rem', position: "relative", left: { xs: "2rem", sm: "3rem", md: "none"} }}
          >
            <Box sx={{mx:'1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={pagaGuayaquil} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.alternate" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  Paga Guayaquil
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Portal para el pago de impuestos prediales.
                  </Typography>
                  <Link to="https://pagaguayaquil.com" color="text.alternate"> Conoce más <ArrowForwardIcon color='primary' fontSize='small'  sx={{ ml: '1rem' }}/> </Link>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={pagaAnt} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.alternate" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  Paga ANT
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Plataforma para el pago de valores de trámites vehiculares.
                  </Typography>
                  <Link to="https://pagaant.com/" color="text.alternate"> Conoce más <ArrowForwardIcon color='primary' fontSize='small'  sx={{ ml: '1rem' }}/> </Link>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={pichincha} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.alternate" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  Pichincha
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Sitio de pago para la tasa de mejormiento vial.
                  </Typography>
                  <Link to="https://prefecturapichinchapagos.com" color="text.alternate"> Conoce más <ArrowForwardIcon color='primary' fontSize='small'  sx={{ ml: '1rem' }}/> </Link>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={electricaQuito} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.alternate" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  Eléctrica de Quito
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Portal para el pago del servicio eléctrico de Quito y sus valles.
                  </Typography>
                  <Link to="https://prefecturapichinchapagos.com" color="text.alternate"> Conoce más <ArrowForwardIcon color='primary' fontSize='small'  sx={{ ml: '1rem' }}/> </Link>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={mintel} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.alternate" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  Mintel
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Ventanilla digital de trámites para Ecuador.
                  </Typography>
                  <Link to="https://www.telecomunicaciones.gob.ec/" color="text.alternate"> Conoce más <ArrowForwardIcon color='primary' fontSize='small'  sx={{ ml: '1rem' }}/> </Link>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={guayasamin} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.gray" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  Guayasamín
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Plataforma pra el pago del TAG de peaje de Guayasamín.
                  </Typography>
                  <Typography color="text.gray" variant="body2" sx={{ fontWeight: 'medium' }}> Próximamente </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{display: 'flex'}}>
              <img src={colombia} alt="Ecuador"/>
              <Typography color="text.grey" variant="h3" sx={{position: 'relative', bottom: '-1.2rem', mx: '1.2rem'}}>
                Colombia
              </Typography>
            </Box>
          </Grid>
          <Grid 
            container lg={8} md={8} sm={12} xs={12}
            spacing={{ xs: 'none', sm: 'none', md: 3 }}
            direction="row"
            justifyContent={{ xs: "center", sm:"center", md:"flex-start" }}
            alignItems={{ xs: "center", sm:"center", md:"flex-start" }}
            columns={12}
            sx={{ py:'2rem', position: "relative", left: { xs: "2rem", sm: "3rem", md: "-4rem"} }}
          >
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={snr} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.alternate" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  SNR
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Organismo que vigila y controla los servicios públicos relativos a la propiedad inmobiliaria.
                  </Typography>
                  <Link to="https://www.certificadotradicionylibertad.com/" color="text.alternate"> Conoce más <ArrowForwardIcon color='primary' fontSize='small'  sx={{ ml: '1rem' }}/> </Link>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={runt} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height:{md:'200px', xs:'220px'}, width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.alternate" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  RUNT
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Entidad que administra y consolida los datos referentes al secor de tránsito y transporte.
                  </Typography>
                  <Link to="https://www.historialvehicular.co/" color="text.alternate"> Conoce más <ArrowForwardIcon color='primary' fontSize='small'  sx={{ ml: '1rem' }}/> </Link>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{display: 'flex'}}>
              <img src={peru} alt="Perú"/>
              <Typography color="text.grey" variant="h3" sx={{position: 'relative', bottom: '-1.2rem', mx: '1.2rem'}}>
                Perú
              </Typography>
            </Box>
          </Grid>
          <Grid 
            container lg={8} md={8} sm={12} xs={12}
            spacing={{ xs: 'none', sm: 'none', md: 3 }}
            direction="row"
            justifyContent={{ xs: "center", sm:"center", md:"left" }}
            alignItems={{ xs: "center", sm:"center", md:"left" }}
            columns={12}
            sx={{ py:'2rem', position: "relative", left: { xs: "2rem", sm: "3rem", md: "-4rem"} }}
          >
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={bancoNacion} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.grey" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  Banco de la Nación
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Plataforma de déposito de los fondos del sector público, nacional y empresas del estado.
                  </Typography>
                  <Typography color="text.gray" variant="body2" sx={{ fontWeight: 'medium' }}> Próximamente </Typography>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={covi} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.grey" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  COVI
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Portal de recarga del TAG y pago del peaje de la carretera Panamericana del Sur.
                  </Typography>
                  <Typography color="text.gray" variant="body2" sx={{ fontWeight: 'medium' }}> Próximamente </Typography>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={facilita} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.grey" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  Facilita
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Plataforma integral de solicitudes digitales del estado peruano.
                  </Typography>
                  <Typography color="text.gray" variant="body2" sx={{ fontWeight: 'medium' }}> Próximamente </Typography>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{mx: '1rem'}}>
              <Box sx={{position: 'relative', left: {xs: '-1rem', md:'7rem'}, bottom: {xs: '-1.2rem', md:'-3rem'}, zIndex: "1" }}>
                <img src={poderJudicial} alt="Paga Guayaquil" />
              </Box>
              <Card sx={{height: '200px', width:{sm: '90%', md: '320px' }, p: '1rem', borderRadius: '15px'}}>
                <Typography color="text.grey" variant="h4" textAlign={{md:'left', xs:'center'}} mt={{xs:'1rem', md: 'none'}}>
                  Poder Judicial
                </Typography>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.grey" variant="caption" textAlign={'center'} my={'1rem'}>
                    Portal donde litigan los abogados del estado peruano.
                  </Typography>
                  <Typography color="text.gray" variant="body2" sx={{ fontWeight: 'medium' }}> Próximamente </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default RecaudoProducts
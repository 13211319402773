import React from 'react';
import { LocaleTypes } from '../data/locales';

import {
  RecaudoHero,
  RecaudoInKushki,
  RecaudoProducts,
  RecaudoCTA
} from '../components/recaudo'

import IndexLayout from '../layouts';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

import '../styles/styles.scss'

interface RecaudoProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location
}

const solucionesDeRecaudo: React.FC<RecaudoProps> = ({ pathContext, location }) => {

  const content = (
    <>
      <SEO title="Soluciones de Recaudo" description="Soluciones de Recaudo" />

      <div className="ingenio-bg-hero-recaudo">
        <RecaudoHero />
      </div>

      <RecaudoInKushki />

      <RecaudoProducts />

      <RecaudoCTA />
      
      <div className="ingenio-bg-footer">
        <Footer />
      </div>
    </>
  )

  return (
    <IndexLayout location={location} content={content}  />
  )
}

export default solucionesDeRecaudo